<template>
	<div class="mobile-views-my-expense--component-wrapper">
		<ul class="ul-list-items" :class="rendListClass">
			<ExpenseItem
				v-for="(item, i) in itemsList"
				:key="i"
				:item="item"
				:type="type"
				@row-clicked="rowClicked(item)"
				@show-picture="showPicture"
				@handle-delete-expense="handleDeleteExpense"
			/>
			<!-- {{ itemsList }} -->
		</ul>
	</div>
</template>

<script>
import { isNil } from '~utils';
import ExpenseItem from './Item';

// const authorizedCurrentExpenseList = ["submited", "not-submited"]; // 0 = not-submited, 1 = submited
const authorizedCurrentExpenseList = [0, 1];

export default {
	name: 'MobileViewsMyExpenseListComponent',
	components: { ExpenseItem },
	props: {
		itemsList: {
			type: Array,
			required: true,
			default: () => []
		},
		// type: {
		//   type: String,
		//   default: "not-submited",
		//   required: true,
		//   validator: v => authorizedCurrentExpenseList.includes(v)
		// },
		type: {
			type: Number,
			default: 0,
			required: true,
			validator: (v) => authorizedCurrentExpenseList.includes(v)
		}
	},
	computed: {
		rendListClass() {
			return `${this.type}-expenses--list`;
		}
	},
	methods: {
		/**
		 * @param {Object} item
		 * @return {Emitter}
		 */
		rowClicked(item) {
			if (isNil(item)) return;
			this.$emit('row-clicked', {
				type: this.type,
				expense: item
			});
		},
		showPicture(images) {
			this.$emit('show-picture', images);
		},
		handleDeleteExpense(item) {
			this.$emit('handle-delete-expense', item);
		}
	}
};
</script>
