<template>
	<MobileExpenseCard :item="item" :custom-class="rendHolderClass" @row-clicked="handlerExpenseClicked">
		<article v-html="`${item.description ? item.description : '&nbsp;'}`" />
		<div v-if="type === 0" class="little-card">
			<div v-if="item.type === 2" class="mileage-presentation">
				<span class="list-item" v-html="rendListElem(FormMSG(1, 'From'), item.fromLocation)" />
				<span class="list-item" v-html="rendListElem(FormMSG(2, 'To'), item.toLocation)" />
				<span class="km"
					><b>{{ item.km }}</b
					>{{ distanceUnit }}</span
				>
				<span class="list-item" v-html="rendListElem('Kg CO2', item.kgCoTwo)" />
			</div>
			<div v-else class="invoice-presentation">
				<span class="list-item" v-html="rendListElem(FormMSG(5, 'Supplier'), item.supplierName)" />
				<span class="list-item" v-html="rendListElem(FormMSG(6, 'Category'), item.categoryName)" />
				<span class="list-item" v-html="rendListElem(FormMSG(3, 'Department'), item.departmentName)" />
				<span class="list-item" v-html="rendListElem(FormMSG(4, 'Paiement Type'), item.paiementTypeName)" />
				<span class="list-item" v-html="rendListElem('Kg CO2', item.kgCoTwo)" />
			</div>
		</div>
		<div v-if="type === 1" class="little-card">
			<div v-if="item.type === 2" class="mileage-presentation">
				<span class="list-item" v-html="rendListElem('Kg CO2', item.kgCoTwo)" />
			</div>
			<div v-else class="invoice-presentation">
				<span class="list-item" v-html="rendListElem(FormMSG(3, 'Department'), item.departmentName)" />
				<span class="list-item" v-html="rendListElem('Kg CO2', item.kgCoTwo)" />
			</div>
		</div>
		<div v-if="type === 0" class="btn-holder">
			<b-button variant="success" v-if="item.images && item.images.length" @click.prevent.stop="showPicture(item.images)">
				<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
			</b-button>
			<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" @click.prevent.stop="handleDeleteExpense(item)" />
		</div>
	</MobileExpenseCard>
</template>

<script>
import { formatTableDate } from '~helpers';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import MobileExpenseCard from '@/components/ExpenseService/MobileExpenseCard';
import { store } from '@/store';

const authorizedCurrentExpenseList = [0, 1];

export default {
	name: 'MobileViewsMyExpenseItemComponent',
	mixins: [languageMessages, globalMixin],
	props: {
		item: {
			type: Object,
			required: true,
			default: () => {}
		},
		type: {
			type: Number,
			default: 0,
			required: true,
			validator: (v) => authorizedCurrentExpenseList.includes(v)
		}
	},
	components: {
		MobileExpenseCard
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendDate() {
			return formatTableDate(this.item.date);
		},

		/**
		 * @return {String}
		 */
		rendHolderClass() {
			return `item_type__${this.type}`;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},
	methods: {
		handlerExpenseClicked(item) {
			this.$emit('row-clicked', item);
		},

		/**
		 * @param {String} label
		 * @param {Number|String} value
		 * @return {String}
		 */
		rendListElem(label, value) {
			if (value == '') return '';

			return `
        <div class="tiny-label">${label}</div>
        <div class="tiny-value">${value}</div>
      `;
		},

		showPicture(images) {
			this.$emit('show-picture', images);
		},
		handleDeleteExpense(item) {
			this.$emit('handle-delete-expense', item);
		}
	}
};
</script>

<style lang="scss" scoped>
.btn-holder {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
	button:not(:first-child) {
		margin-left: 20px;
	}
}
</style>
