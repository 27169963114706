var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 62 83.75",
        x: "0px",
        y: "0px",
      },
    },
    [
      _c("title", [_vm._v("Milage")]),
      _c("g", { attrs: { "data-name": "Layer 2" } }, [
        _c("g", { attrs: { "data-name": "Layer 1" } }, [
          _c("path", {
            attrs: {
              d: "M51,47.66a4,4,0,1,0,4,4A4,4,0,0,0,51,47.66Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,51,53.66Z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M11,47.66a4,4,0,1,0,4,4A4,4,0,0,0,11,47.66Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11,53.66Z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M60,34H53.71a24.61,24.61,0,0,1-.78-3.4A13,13,0,0,0,40,19H22A13,13,0,0,0,9.1,30.48a9.75,9.75,0,0,1-.74,2.79L8.1,34H2a2.11,2.11,0,0,0-2,2.2v3.6A2.11,2.11,0,0,0,2,42H4.7L2.78,45.35a6,6,0,0,0-.79,3V56a5,5,0,0,0,3,4.58V64a3,3,0,0,0,3,3h5a3,3,0,0,0,3-3V61H45v3a3,3,0,0,0,3,3h5a3,3,0,0,0,3-3V60.9A5,5,0,0,0,60,56V48.33a6,6,0,0,0-.79-3L57.29,42H60a2.11,2.11,0,0,0,2-2.2V36.2A2.11,2.11,0,0,0,60,34ZM10.22,34a11.53,11.53,0,0,0,.87-3.3A11,11,0,0,1,22,21H40a11,11,0,0,1,10.94,9.81,23.83,23.83,0,0,0,1.13,4.56L53.52,39H51.61a2,2,0,0,0-1.79,1.11l-.45.89H47.7a7,7,0,0,0-13.4,0H12.61l-.45-.89A2,2,0,0,0,10.37,39h-2Zm35.35,7H36.44a5,5,0,0,1,9.13,0ZM2.07,40A.31.31,0,0,1,2,39.8V36.2c0-.12,0-.19,0-.2H7.38L6.1,39.54,5.84,40ZM14,64a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V61h7Zm40,0a1,1,0,0,1-1,1H48a1,1,0,0,1-1-1V61h7Zm4-15.67V56a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V48.33a4,4,0,0,1,.53-2L7.57,41h2.8l.45.9A2,2,0,0,0,12.61,43H49.37a2,2,0,0,0,1.79-1.11l.45-.89h2.8l3.05,5.34A4,4,0,0,1,58,48.33Zm2-8.53c0,.12,0,.19,0,.2H56.14l-.22-.39L54.48,36h5.45a.31.31,0,0,1,.07.22Z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M41,48H21a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H41a2,2,0,0,0,2-2V50A2,2,0,0,0,41,48ZM21,54V50H41v4Z",
            },
          }),
          _c("path", {
            attrs: { d: "M27,27h8a1,1,0,0,0,0-2H27a1,1,0,0,0,0,2Z" },
          }),
          _c("path", {
            attrs: {
              d: "M12.14,13.51a1,1,0,0,0,1.71-1l-2.5-4.17a43.88,43.88,0,0,1,4.37-2.44L17,8.67a1,1,0,0,0,1.82-.84L17.54,5a39.83,39.83,0,0,1,5.21-1.84l.53,3.41a1,1,0,0,0,1,.85h.16a1,1,0,0,0,.83-1.14l-.54-3.46a.93.93,0,0,0,0-.11A30.73,30.73,0,0,1,30,2V6a1,1,0,0,0,2,0V2a33.34,33.34,0,0,1,6,.74s0,0,0,.07L37.47,6.3a1,1,0,0,0,.83,1.14h.16a1,1,0,0,0,1-.85L40,3.26a35.55,35.55,0,0,1,5.17,1.88L43.9,7.83a1,1,0,1,0,1.82.84L46.94,6a38.53,38.53,0,0,1,3.83,2.26l-2.62,4.2a1,1,0,0,0,1.7,1.06l3.12-5a1,1,0,0,0-.27-1.34A37.39,37.39,0,0,0,31,0C21.57,0,13.45,4.49,9.44,7.17a1,1,0,0,0-.3,1.35Z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M35.27,6.08a1,1,0,0,0-1.35.4L31.46,11A3,3,0,1,0,31,17a3,3,0,0,0,.86-.13A3,3,0,0,0,33.22,12l2.46-4.56A1,1,0,0,0,35.27,6.08Zm-3.39,8.39h0a1,1,0,1,1-.4-1.35A1,1,0,0,1,31.88,14.48Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }