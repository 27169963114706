<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		:title="FormMSG(1, 'Expense Title')"
		:ok-title="FormMSG(2, 'Add')"
		:cancel-title="FormMSG(3, 'Cancel')"
		:hide-header-close="true"
		modal-class="modal-mobile"
		v-model="isOpen"
		cancel-variant="light"
		@ok="handleAddTitle"
		@cancel="onCancel"
		@hidden="onCancel"
		size="md"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div class="form">
			<b-form @submit="handleAddTitle">
				<b-form-group :label="FormMSG(4, 'Title')" label-for="expense-title" :label-cols="12">
					<b-form-textarea
						id="expense-title"
						ref="expense-title"
						v-model="title"
						rows="3"
						autofocus
						:class="{ 'is-invalid': isSubmitted && $v.title.$error }"
					/><!--  -->
					<div v-if="isSubmitted && !$v.title.required" class="invalid-feedback">
						{{ FormMSG(5, 'Please, length should be at least') }}&nbsp;{{ minLengthTitle }}
					</div>
				</b-form-group>
			</b-form>
		</div>
	</b-modal>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { isNil } from '@/shared/utils';

export default {
	name: 'TitleExpenseModal',
	mixins: [languageMessages, validationMixin],
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		reinitializeValue: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			title: '',
			titleInValid: true,
			minLengthTitle: 4,
			isSubmitted: false
		};
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	methods: {
		handleAddTitle(e) {
			e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			this.$emit('title-expense-modal:add', this.title);
		},
		onCancel() {
			this.$emit('title-expense-modal:cancel', false);
		}
	},
	validations: {
		title: {
			required,
			min: minLength(4)
		}
	},
	watch: {
		reinitializeValue: {
			handler(n) {
				if (!isNil(n) && n === true) {
					setTimeout(() => {
						this.title = '';
					}, 150);
				}
			},
			immediate: true,
			deep: true
		}
	}
};
</script>
