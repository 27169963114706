var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-expense-mobile-view-component-wrapper" },
    [
      _c(
        "div",
        { staticClass: "card main-container" },
        [
          _c("loading", {
            attrs: {
              active: _vm.loading,
              "is-full-page": "",
              color: "#00A09C",
            },
            on: {
              "update:active": function ($event) {
                _vm.loading = $event
              },
            },
          }),
          _c(
            "b-row",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("h3", { domProps: { innerHTML: _vm._s(_vm.title) } }),
              ]),
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("button", {
                  staticClass: "switcher-list-link",
                  class: _vm.rendSwitcherLink(0),
                  domProps: { innerHTML: _vm._s(_vm.tabOneCaption()) },
                  on: {
                    click: function ($event) {
                      return _vm.setCurrentExpenseList(0)
                    },
                  },
                }),
                _c("span", [_vm._v(" ")]),
                _c("button", {
                  staticClass: "switcher-list-link",
                  class: _vm.rendSwitcherLink(1),
                  domProps: { innerHTML: _vm._s(_vm.tabTwoCaption()) },
                  on: {
                    click: function ($event) {
                      return _vm.setCurrentExpenseList(1)
                    },
                  },
                }),
              ]),
            ],
            1
          ),
          _c("ItemsList", {
            key: _vm.listItemsListRefreshKey,
            attrs: {
              "items-list": _vm.currentExpensesItemList,
              type: _vm.currentExpenseTab,
            },
            on: {
              "row-clicked": _vm.handleExpenseClicked,
              "show-picture": _vm.showPicture,
              "handle-delete-expense": _vm.handleDeleteExpense,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }