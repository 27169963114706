<template>
	<li class="extra-rounded my-expense-mobile-view--component-wrapper">
		<div class="card is-shadow" @click="handlerExpenseClicked">
			<div class="content_holder" :class="customClass">
				<div class="col_pic">
					<div class="icon-holder">
						<!-- invoice -->
						<InvoiceIconSVG v-if="item.type === 1" />

						<!-- mileage -->
						<CarMileageIconSVG v-if="item.type === 2" />

						<!-- ticket -->
						<WalletIconSVG v-if="item.type === 0" />

						<span v-html="item.typeString" class="label" />
					</div>
				</div>
				<div class="col_content">
					<div class="head">
						<span class="label" v-html="item.validatedStatus" :class="rendCardLabel" />
						<span class="amount" v-html="rendAmountTotal" />
					</div>
					<div class="info-container">
						<slot />
						<span v-html="rendDate" class="date" />
					</div>
				</div>
			</div>
		</div>
	</li>
</template>

<script>
import { rendCurrency, formatTableDate } from '~helpers';
import languageMessages from '@/mixins/languageMessages';
import WalletIconSVG from '@/components/icons/wallet';
import InvoiceIconSVG from '@/components/icons/invoice';
import CarMileageIconSVG from '@/components/icons/car-mileage';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
// const authorizedCurrentExpenseList = ['submited', 'not-submited']

export default {
	name: 'MobileExpenseCardComponent',
	components: { WalletIconSVG, InvoiceIconSVG, CarMileageIconSVG },
	mixins: [languageMessages, isSingleProjectMixin],
	props: {
		item: {
			type: Object,
			required: true,
			default: () => {}
		},
		customClass: {
			type: String,
			default: '',
			required: false
		}
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendDate() {
			return formatTableDate(this.item.date);
		},

		rendAmountTotal() {
			const _a = rendCurrency(this.item.amountTotal);
			return `<b>${_a}</b>`;
		},

		/**
		 * @return {Array}
		 */
		rendCardLabel() {
			return [
				`text-${this.item.validatedClass}`,
				{
					'is-hidden': this.isFilmSingle
				}
			];
		}
	},
	methods: {
		handlerExpenseClicked() {
			this.$emit('row-clicked', this.item);
		}
	}
};
</script>

<style lang="scss" scoped>
li.extra-rounded {
	list-style: none;
}

.icon-holder .label {
	display: block;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
	padding-top: 2px;
}

.col_content .head {
	font-size: 0.9rem;
	.label {
		font-weight: bold;
		&.is-hidden {
			opacity: 0;
		}
	}
}
</style>
