var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "extra-rounded my-expense-mobile-view--component-wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "card is-shadow",
          on: { click: _vm.handlerExpenseClicked },
        },
        [
          _c("div", { staticClass: "content_holder", class: _vm.customClass }, [
            _c("div", { staticClass: "col_pic" }, [
              _c(
                "div",
                { staticClass: "icon-holder" },
                [
                  _vm.item.type === 1 ? _c("InvoiceIconSVG") : _vm._e(),
                  _vm.item.type === 2 ? _c("CarMileageIconSVG") : _vm._e(),
                  _vm.item.type === 0 ? _c("WalletIconSVG") : _vm._e(),
                  _c("span", {
                    staticClass: "label",
                    domProps: { innerHTML: _vm._s(_vm.item.typeString) },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col_content" }, [
              _c("div", { staticClass: "head" }, [
                _c("span", {
                  staticClass: "label",
                  class: _vm.rendCardLabel,
                  domProps: { innerHTML: _vm._s(_vm.item.validatedStatus) },
                }),
                _c("span", {
                  staticClass: "amount",
                  domProps: { innerHTML: _vm._s(_vm.rendAmountTotal) },
                }),
              ]),
              _c(
                "div",
                { staticClass: "info-container" },
                [
                  _vm._t("default"),
                  _c("span", {
                    staticClass: "date",
                    domProps: { innerHTML: _vm._s(_vm.rendDate) },
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }