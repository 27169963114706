<template>
	<div class="my-expense-mobile-view-component-wrapper">
		<div class="card main-container">
			<loading :active.sync="loading" is-full-page color="#00A09C" />

			<b-row style="margin-bottom: 20px">
				<b-col cols="12">
					<h3 v-html="title" />
				</b-col>
				<b-col cols="12">
					<button v-html="tabOneCaption()" class="switcher-list-link" :class="rendSwitcherLink(0)" @click="setCurrentExpenseList(0)" />
					<span>&nbsp;</span>
					<button v-html="tabTwoCaption()" class="switcher-list-link" :class="rendSwitcherLink(1)" @click="setCurrentExpenseList(1)" />
				</b-col>
			</b-row>
			<ItemsList
				:key="listItemsListRefreshKey"
				:items-list="currentExpensesItemList"
				:type="currentExpenseTab"
				@row-clicked="handleExpenseClicked"
				@show-picture="showPicture"
				@handle-delete-expense="handleDeleteExpense"
			/>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { isNil } from '~utils';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';

import ItemsList from './List';

const authorizedCurrentExpenseList = [1, 0];

export default {
	name: 'MyExpensesMobileViewComponent',
	mixins: [languageMessages, isSingleProjectMixin],
	components: { Loading, ItemsList },
	props: {
		loading: {
			type: Boolean,
			required: false,
			default: false
		},
		notSubmitedExpenses: {
			type: Array,
			required: true,
			default: () => []
		},
		title: {
			type: String,
			required: true,
			default: 'title'
		},
		submitedExpenses: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		const currentExpensesItemList = this.notSubmitedExpenses;
		return {
			currentExpensesItemList: [],
			listItemsListRefreshKey: 0
		};
	},
	computed: {
		currentExpenseTab: {
			get() {
				return this.$store.getters['expenses/activeTab'];
			},
			set(tab) {
				this.$store.dispatch('expenses/setActiveTab', tab);
			}
		}
	},
	watch: {
		notSubmitedExpenses: {
			handler(list) {
				if (this.currentExpenseTab !== 0) return;
				this.currentExpensesItemList = list;
			},
			deep: true
		},
		submitedExpenses: {
			handler(list) {
				if (this.currentExpenseTab === 0) return;
				this.currentExpensesItemList = list;
			},
			deep: true
		}
	},
	// computed: {
	//   currentExpensesItemList: {
	//     get() {
	//       return this.notSubmitedExpenses;
	//     },
	//     set(list) {
	//       if (isNil(list)) return;
	//       return [...[], ...list];
	//     }
	//   }
	// },
	methods: {
		tabOneCaption() {
			if (this.isFilmSingle) {
				return this.FormMSG(123, 'Expense items');
			} else {
				return this.FormMSG(1, 'Not submitted');
			}
		},
		tabTwoCaption() {
			if (this.isFilmSingle) {
				return this.FormMSG(121, 'Expense sheets');
			} else {
				return this.FormMSG(2, 'Submitted');
			}
		},
		// initListItems() {
		//   this.injectCurrentExpenseList(this.notSubmitedExpenses);
		// },

		// /**
		//  * @param {Array} list
		//  */
		// injectCurrentExpenseList(list) {
		//   if (isNil(list)) return;
		//   this.currentExpensesItemList = [...[], ...list];
		// },
		showPicture(images) {
			this.$emit('show-picture', images);
		},
		handleDeleteExpense(item) {
			this.$emit('handle-delete-expense', item);
		},

		/**
		 * @param {String} type
		 */
		setCurrentExpenseList(type) {
			if (!this.checkType(type)) return;
			this.listItemsListRefreshKey = this.listItemsListRefreshKey + 1;
			// if (type === 0)
			//   this.injectCurrentExpenseList(this.notSubmitedExpenses);
			// if (type === 1)
			//   this.injectCurrentExpenseList(this.submitedExpenses);
			if (type === 0) this.currentExpensesItemList = this.notSubmitedExpenses;
			if (type === 1) this.currentExpensesItemList = this.submitedExpenses;
			this.currentExpenseTab = type;
		},

		/**
		 * @param {Object} expense
		 */
		notSubmitedExpenseRowClicked(expense) {
			this.$emit('not-submited-expense-clicked', expense);
		},

		/**
		 * @param {Object} expense
		 */
		SubmitedExpenseRowClicked(expense) {
			this.$emit('submited-row-clicked', expense);
		},

		/**
		 * @param {Object} row
		 */
		handleExpenseClicked(row) {
			if (isNil(row)) return;
			if (row.type === 0) this.notSubmitedExpenseRowClicked(row.expense);
			if (row.type === 1) this.SubmitedExpenseRowClicked(row.expense);
		},

		/**
		 *
		 *
		 * HELPERS
		 *
		 *
		 */

		/**
		 * @param {String} type
		 * @return {Boolean}
		 */
		checkType(type) {
			return authorizedCurrentExpenseList.includes(type);
		},

		/**
		 * @param {String} type
		 * @return {String}
		 */
		rendSwitcherLink(type) {
			if (isNil(type) || !this.checkType(type)) return 'btn btn-secondary';
			return this.currentExpenseTab === type ? 'is-active btn btn-primary' : 'btn btn-outline-secondary';
		}
	}
};
</script>
