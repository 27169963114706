import { store } from '@/store';

export default {
	name: 'profileMixin',
    data(){
        return {}
    },
    computed:{
        checkExpMandatory() {
			return store.getCurrentProjectConfig().mandatoryExpenseReceipt;
		},
		checkInvMandatory() {
			return store.getCurrentProjectConfig().mandatoryInvoiceReceipt;
		},
    }
};