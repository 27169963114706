<template>
	<svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" x="0px" y="0px" :style="rendStyle">
		<!-- <metadata>
      <?xpacket begin="&#65279;" id="W5M0MpCehiHzreSzNTczkc9d"?><x:xmpmeta
        xmlns:x="adobe:ns:meta/"
        x:xmptk="Adobe XMP Core 5.6-c145 79.162955, 2018/05/10-01:07:16        "
      ></x:xmpmeta
      ><?xpacket end="w"?>
    </metadata> -->
		<g>
			<path
				d="M472,80H403.314L341.657,18.343a8,8,0,0,0-11.314,0L312,36.686,293.657,18.343a8,8,0,0,0-11.314,0L264,36.686,245.657,18.343a8,8,0,0,0-11.314,0L116.686,136H56a39.933,39.933,0,0,0-23.287,7.484A39.968,39.968,0,0,1,72,96h72V80H72a56.063,56.063,0,0,0-56,56V440a56.063,56.063,0,0,0,56,56H424a24.028,24.028,0,0,0,24-24V200a24.028,24.028,0,0,0-24-24H72a39.976,39.976,0,0,1-32.792-17.135A23.958,23.958,0,0,1,56,152H480v56H464v16h16V376a8.009,8.009,0,0,1-8,8h-8v16h8a24.028,24.028,0,0,0,24-24V104A24.028,24.028,0,0,0,472,80ZM72,192H424a8.009,8.009,0,0,1,8,8V472a8.009,8.009,0,0,1-8,8H72a40.045,40.045,0,0,1-40-40V175.154A55.833,55.833,0,0,0,72,192Zm67.313-56L240,35.313,252.686,48l-88,88Zm48,0L288,35.313,300.686,48l-88,88ZM360,136H283.313l29.228-29.228a42.064,42.064,0,0,0,46.917,0L388.687,136Zm51.314,0L365.657,90.343a8,8,0,0,0-11.314,0,25.941,25.941,0,0,1-36.686,0,8,8,0,0,0-11.314,0L260.686,136H235.313L336,35.313,436.687,136Zm48,0-40-40H472a8.009,8.009,0,0,1,8,8v32Z"
			></path>
			<rect x="104" y="208" width="32" height="16"></rect>
			<rect x="160" y="208" width="32" height="16"></rect>
			<rect x="216" y="208" width="32" height="16"></rect>
			<rect x="272" y="208" width="32" height="16"></rect>
			<rect x="328" y="208" width="32" height="16"></rect>
			<rect x="104" y="448" width="32" height="16"></rect>
			<rect x="48" y="208" width="32" height="16"></rect>
			<rect x="64" y="448" width="16" height="16"></rect>
			<rect x="160" y="448" width="32" height="16"></rect>
			<rect x="216" y="448" width="32" height="16"></rect>
			<rect x="272" y="448" width="32" height="16"></rect>
			<rect x="328" y="448" width="32" height="16"></rect>
			<path d="M416,456V432H400v16H384v16h24A8,8,0,0,0,416,456Z"></path>
			<rect x="400" y="376" width="16" height="32"></rect>
			<rect x="400" y="320" width="16" height="32"></rect>
			<rect x="400" y="264" width="16" height="32"></rect>
			<path d="M400,240h16V216a8,8,0,0,0-8-8H384v16h16Z"></path>
			<path d="M328,400h40a8,8,0,0,0,8-8V280a8,8,0,0,0-8-8H328a8,8,0,0,0-8,8V392A8,8,0,0,0,328,400Zm8-112h24v96H336Z"></path>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'WalletIconComonent',
	props: {
		svgStyle: {
			type: String,
			required: false,
			default: ''
		}
	},
	computed: {
		rendStyle() {
			const _b = 'shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;';
			return _b + this.svgStyle;
		}
	}
};
</script>

<style scope type="text/css">
.fil0 {
	fill: black;
	fill-rule: nonzero;
}
</style>
