var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mobile-views-my-expense--component-wrapper" },
    [
      _c(
        "ul",
        { staticClass: "ul-list-items", class: _vm.rendListClass },
        _vm._l(_vm.itemsList, function (item, i) {
          return _c("ExpenseItem", {
            key: i,
            attrs: { item: item, type: _vm.type },
            on: {
              "row-clicked": function ($event) {
                return _vm.rowClicked(item)
              },
              "show-picture": _vm.showPicture,
              "handle-delete-expense": _vm.handleDeleteExpense,
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }