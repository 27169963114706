var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(1, "Expense Title"),
        "ok-title": _vm.FormMSG(2, "Add"),
        "cancel-title": _vm.FormMSG(3, "Cancel"),
        "hide-header-close": true,
        "modal-class": "modal-mobile",
        "cancel-variant": "light",
        size: "md",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: {
        ok: _vm.handleAddTitle,
        cancel: _vm.onCancel,
        hidden: _vm.onCancel,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "form" },
        [
          _c(
            "b-form",
            { on: { submit: _vm.handleAddTitle } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(4, "Title"),
                    "label-for": "expense-title",
                    "label-cols": 12,
                  },
                },
                [
                  _c("b-form-textarea", {
                    ref: "expense-title",
                    class: {
                      "is-invalid": _vm.isSubmitted && _vm.$v.title.$error,
                    },
                    attrs: { id: "expense-title", rows: "3", autofocus: "" },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                  _vm.isSubmitted && !_vm.$v.title.required
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                5,
                                "Please, length should be at least"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.minLengthTitle) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }