var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        fill: "#000000",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 100 100",
        x: "0px",
        y: "0px",
      },
    },
    [
      _c("g", { attrs: { "data-name": "01-Invoice" } }, [
        _c("path", {
          attrs: {
            d: "M83.19,23.64a1.11,1.11,0,0,0,0-.25l0-.09a1,1,0,0,0-.19-.29L66.09,5.39a1,1,0,0,0-.76-.3H19.05a1,1,0,0,0-1,1V79.62h-12a1,1,0,0,0-1,1c0,7.79,5.54,14.12,12.35,14.12h56A10,10,0,0,0,83.2,84.51V23.7S83.19,23.66,83.19,23.64Zm-16.82-15L79.86,22.7H66.37Zm-49,84.1c-5.42,0-9.88-4.9-10.32-11.12H62.31V82a13.55,13.55,0,0,0,.08,1.43c0,.13,0,.25,0,.38.05.4.12.79.2,1.18,0,.08,0,.15,0,.23a11.75,11.75,0,0,0,.38,1.32l.12.33q.2.52.42,1l.13.3a12.06,12.06,0,0,0,.65,1.17c0,.08.09.15.14.23q.3.47.63.9c.07.1.15.19.22.28.28.34.56.67.87,1a.46.46,0,0,0,.07.07,11.2,11.2,0,0,0,.9.8l.13.12Zm56,0H71.73l-.37-.11L71,92.51l-.39-.16-.37-.15-.36-.2-.36-.2-.35-.22-.35-.24-.32-.26-.33-.26-.3-.29-.31-.3c-.1-.1-.19-.21-.28-.31L67,89.59l-.26-.34-.27-.36-.23-.36c-.08-.13-.17-.25-.24-.38l-.21-.39c-.07-.13-.15-.26-.21-.4s-.12-.27-.18-.4l-.19-.42L65,86.11c0-.14-.11-.28-.15-.43s-.09-.31-.13-.46l-.12-.43c0-.17-.07-.35-.1-.52s0-.26-.07-.39,0-.4-.07-.6,0-.22,0-.33c0-.31,0-.63,0-1V80.62a1,1,0,0,0-1-1H20.05V7.08H64.37V23.7a1,1,0,0,0,1,1H81.2V84.51A8,8,0,0,1,73.39,92.74Z",
          },
        }),
        _c("path", {
          attrs: {
            d: "M76.52,33.65H24.73a1,1,0,0,0-1,1V67.26a1,1,0,0,0,1,1H76.52a1,1,0,0,0,1-1V34.65A1,1,0,0,0,76.52,33.65Zm-1,6.53H41V35.65H75.52Zm-41.87,0V35.65H39v4.53Zm5.32,2V66.26H33.65V42.18ZM25.73,35.65h5.92v4.53H25.73Zm0,6.53h5.92V66.26H25.73ZM41,66.26V42.18H75.52V66.26Z",
          },
        }),
        _c("path", {
          attrs: { d: "M76.09,71.81H58.58a1,1,0,1,0,0,2H76.09a1,1,0,0,0,0-2Z" },
        }),
        _c("circle", { attrs: { cx: "55.11", cy: "72.81", r: "1" } }),
        _c("path", {
          attrs: { d: "M23.89,18.22H36a1,1,0,0,0,0-2H23.89a1,1,0,0,0,0,2Z" },
        }),
        _c("path", {
          attrs: { d: "M40.14,22.22a1,1,0,0,0,0-2H23.89a1,1,0,0,0,0,2Z" },
        }),
        _c("circle", { attrs: { cx: "43.5", cy: "21.22", r: "1" } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }